import React, { Suspense, useEffect } from 'react';
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
// Importar os componentes de página usando lazy
const Contact = React.lazy(() => import('./pages/Contact'));
const Escola = React.lazy(() => import('./pages/Escola'));
const EspecialistaClinico = React.lazy(() => import('./pages/EspecialistaClinico'));
const Familia = React.lazy(() => import('./pages/Familia'));
function App() {
 

  useDocTitle("Geração Alpha");

  return (
    <>
      <Router>
        <ScrollToTop>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contato" element={<Contact />} />
            <Route path="/escola" element={<Escola />} />
            <Route path="/especialista" element={<EspecialistaClinico />} />
            <Route path="/familia" element={<Familia />} />
         
          </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
