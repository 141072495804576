import React, { Suspense, lazy } from 'react';
import DynamicHead from '../components/DynamicHead';

// Usando lazy loading para componentes
const Clients = lazy(() => import('../components/Clients'));
const Cta = lazy(() => import('../components/Cta'));
const Footer = lazy(() => import('../components/Footer'));
const Hero = lazy(() => import('../components/Hero'));
const Intro = lazy(() => import('../components/Intro'));
const Portfolio = lazy(() => import('../components/Portfolio'));
const Services = lazy(() => import('../components/Services'));

const Home = () => {
    const description = "A Geração Alpha é ecossistema de ensino personalizado onde escolas, famílias e especialistas clínicos podem colaborar de maneira eficiente atendendo uma ampla gama de alunos com diferentes transtornos de aprendizagem e neurodivergências, oferecendo uma abordagem adaptável e inclusiva para o aprendizado na plataforma da Geração Alpha. Incluem-se a Dislexia, TDAH e o Autismo.";
    const keywords = "soluções de ensino, serviços tecnológicos, geração alpha oficial, geração, alpha, transtorno de aprendizagem, neurodivergências, personalização de ensino";
    const title = "Geração Alpha";

    return (
        <>
            <DynamicHead title={title} description={description} keywords={keywords} />
            <Suspense fallback={<div></div>}>
                <Hero />
                <Intro />
                <Services />
                <Portfolio />
                <Clients />
                <Cta />
                <Footer />
            </Suspense>
        </>
    );
};

export default Home;
