import React, { useEffect } from 'react';

function DynamicHead({ title, description, keywords }) {
  useEffect(() => {
    document.title = title;
      document.querySelector('meta[name="description"]').setAttribute('content', description);
      document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
      document.querySelector('meta[property="og:title"]').setAttribute('content', title);
      document.querySelector('meta[property="og:description"]').setAttribute('content', description);
     

  }, [title, description]);

  return null; // Este componente não renderiza nada
}

export default DynamicHead;
